'use strict';

import imagesLoaded from 'imagesloaded';
imagesLoaded.makeJQueryPlugin($);

import { inview } from './module/_inview';
import { anchor } from './module/_anchor';
import { modal } from './module/_modal';
import { scrollbar } from './module/_scrollbar';
import { share } from './module/_share';
import { trailer } from './module/_trailer';
import { fitimg } from './module/_fitimg';
import { hover } from './module/_hover';
import { nav } from './module/_nav';
import { carousel } from './module/_carousel';
import cssVars from 'css-vars-ponyfill';

const main = (() => {
    /**
     * init
     */
    const init = () => {
        scrolled();
        resized();

        anchor.init();
        modal.init();
        scrollbar.init();
        share.init();
        trailer.init();
        fitimg.init();
        hover.init();
        nav.init();
        carousel.init();
        cssVars();
    };

    /**
     * loaded
     */
    const loaded = () => {
        $('body').addClass('is-loaded');

        $('.layout-loading').on('transitionend', () => {
            inview.init();
        });

        // $('.hero__title').on('transitionend', () => {
        //     if ($('#home').length > 0) {
        //         if (!isSkip()) {
        //             setTimeout(() => {
        //                 $('.js-auto-play').trigger('click');
        //             }, 1500);
        //         }
        //     }
        // });
    };

    /**
     * scrolled
     */
    const scrolled = () => {
        if ($(window).scrollTop() >= $(window).height() / 2) {
            $('body').addClass('is-scrolled');
        } else {
            $('body').removeClass('is-scrolled');
        }
    };

    /**
     * resized
     */
    const resized = () => {
        setStyleVarVH();
    };

    /**
     * isSkip
     */
    const isSkip = () => {
        const referrer = document.referrer;
        const domain = document.domain;

        if (referrer.indexOf(domain) > -1) {
            return true;
        } else {
            return false;
        }
    };

    /**
     * setStyleVarVH
     */
    const setStyleVarVH = () => {
        let vh = $(window).outerHeight() * 0.01;
        document.documentElement.style.setProperty('--vh', vh + 'px');
    };

    // public
    return {
        init: init,
        loaded: loaded,
        scrolled: scrolled,
        resized: resized
    };
})();

$(() => {
    main.init();

    $('body')
        .imagesLoaded()
        .done(instance => {
            main.loaded();
        })
        .fail(() => {
            main.loaded();
        });

    $(window).on('scroll', () => {
        main.scrolled();
    });

    $(window).on('resize', () => {
        setTimeout(() => {
            main.resized();
        }, 100);
    });
});
