'use strict';

export const hover = (() => {
    const $hover = $('.js-hover');

    const init = () => {
        let is_touchstart = false;

        $(document).on('mouseover touchstart', '.js-hover', event => {
            const $this = $(event.currentTarget);

            if (event.type === 'touchstart') {
                is_touchstart = true;
                on($this);
            } else {
                if (is_touchstart) {
                    // SPでmouseoverイベントもトリガーされる場合は、2重でonを呼ばない様にする
                    is_touchstart = false;
                } else {
                    on($this);
                }
            }
        });

        $(document).on('mouseout touchend', '.js-hover', event => {
            const $this = $(event.currentTarget);

            off($this);
        });
    };

    const on = $element => {
        $element.addClass('is-on');
    };

    const off = $element => {
        $element.removeClass('is-on');
    };

    // public
    return {
        init: init
    };
})();
