'use strict';

export const share = (() => {
    const $toggle = $('.js-toggle-share');

    /**
     * init
     */
    const init = () => {
        $toggle.on('click', event => {
            toggle();
        });
    };

    /**
     * toggle
     */
    const toggle = () => {
        $('body').toggleClass('is-show-share');
    };

    // public
    return {
        init: init
    };
})();
