'use strict';

import slick from 'slick-carousel';

export const carousel = (() => {
    // const breakPoint = 768;

    /**
     * init
     */
    const init = () => {

        if ($('.js-carousel-banner').length) {
            $('.js-carousel-banner')
                .not('.slick-initialized')
                .slick({
                    fade: false,
                    arrows: true,
                    dots: false,
                    autoplay: true,
                    speed: 600,
                    autoplaySpeed: 5000,
                    infinite: true,
                    slidesToShow: 3,
                    pauseOnHover: false,
                    pauseOnFocus: false,
                    variableWidth: true,
                    prevArrow: '<div class="slick-arrow slick-arrow--prev js-hover"></div>',
                    nextArrow: '<div class="slick-arrow slick-arrow--next js-hover"></div>',
                });
        }


        if ($('.js-carousel-member').length) {
            $('.js-carousel-member')
                .not('.slick-initialized')
                .slick({
                    speed: 5000,
                    autoplaySpeed: 0,
                    cssEase: 'linear',
                    arrows: false,
                    dots: false,
                    autoplay: true,
                    infinite: true,
                    centerMode: true,
                    pauseOnFocus: false,
                    pauseOnHover: false,
                    pauseOnDotsHover: false,
                    variableWidth: true,
                });
        }
    };


    // public
    return {
        init: init
    };
})();