'use strict';

import SimpleBar from 'simplebar';

export const scrollbar = (() => {

    const init = () => {
        $('.js-scrollbar').each((index, element) => {
            new SimpleBar(element, {
                autoHide: false
            });
        });
    };

    return {
        init: init
    };
})();
