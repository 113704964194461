'use strict';

import 'waypoints/lib/jquery.waypoints.min';

export const inview = (() => {

    const init = () => {
        $('.js-inview').each((index, element) => {
            const waypoint = new Waypoint({
                element: element,
                handler: direction => {
                    $(element).addClass('is-inview');
                },
                offset: $(element).data('offset') ? $(element).data('offset') : '75%'
            });
        });

        $(window).on('resize', () => {
            setTimeout(() => {
                Waypoint.refreshAll();
            }, 100);
        });
    };

    // public
    return {
        init: init
    };
})();
