'use strict';

export const trailer = (() => {
    const init = () => {
        let $player = null;
        let player = null;
        let $caption = null;

        $('.js-play-trailer').each((index, element) => {
            const $this = $(element);

            $this.on('click', () => {
                const id = $this.data('id');

                if ($player !== null) {
                    $player.removeClass('is-active');
                    $caption.removeClass('is-active');
                }

                $player = $('.trailer-player__item:eq(' + id + ')');
                player = $player.find('iframe')[0];
                $caption = $('.trailer-caption__item:eq(' + id + ')');

                $player.addClass('is-active');
                $caption.addClass('is-active');
                player.contentWindow.postMessage('{"event": "command", "func": "playVideo", "args": ""}', '*');
            });
        });

        $('.js-stop-trailer').on('click', () => {
            player.contentWindow.postMessage('{"event": "command", "func": "stopVideo", "args": ""}', '*');
        });

        $('.trailer-caption__item').each((index, element) => {
            const $this = $(element);

            $this.on('click', function() {
                player.contentWindow.postMessage('{"event": "command", "func": "stopVideo", "args": ""}', '*');
                $player.removeClass('is-active');
                $caption.removeClass('is-active');

                $player = $('.trailer-player__item:eq(' + index + ')');
                player = $player.find('iframe')[0];
                $caption = $this;

                $player.addClass('is-active');
                $caption.addClass('is-active');
                player.contentWindow.postMessage('{"event": "command", "func": "playVideo", "args": ""}', '*');
            });
        });
    };

    return {
        init: init
    };
})();
